.dropdown {background-color : #ededed; font-weight: bold; letter-spacing: 1px;}

.dropdown li a {text-decoration : none; color : #2d2f31;}

 
.dropdown {
  width : 230px; 
  background: #d9d9d9;
  margin : 20px auto; 
  border-radius: 5px;
 
}

.dropdown span {
  padding : 15px;
  background : #2d2f31; 
  color : white;
  font-size : 1.2em;
  /* font-variant : small-caps; */
  cursor : pointer;
  display: block;
  width: 230px;
  
}

.dropdown span::after {
  float: right;
  right: 10%;
  content: "+";
}

.slide {
  clear:both;
  width:100%;
  height:0px;
  overflow: hidden;
  text-align: left;
  transition: height .4s ease;
}

.slide li {padding : 10px;}

#touch {position: absolute; opacity: 0; height: 0px;}    

#touch:checked + .slide {height: 100px;} 

.startstop
{
  display: flex;
	justify-content: center;
  /* margin-bottom: 10px; */
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1;

}
.startstop button
{
  margin: 10px;
  background-color: #4CAF50;
  color: white;
  padding: 15px 32px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 12px;
}

.video1{
  width: 100%;
  height: 90dvh;
  top: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}
  
.video-player {
  /* margin: 0 auto; */
  /* margin-top: 3px; */
  width: 100%;
  height: 100%;
  display: flex; 
  align-items: center;
  justify-content: center;
  align-content: center;
 
  /* position: relative; */
  .orrec{
    height: 100%;

  }
  
  .liveScreen-player{
    float: left;
    height:90%;
    
    position: relative;

  } 
  .liveCam-player{
 
    /* float: right; */
    height:30%;
    float: right;
    /* margin-right: 1%; */
    margin-left:0.15cm;
    position: relative; 
  }
  .ScreenRecorded{
    float: left;
    height:70%;
   
  }
  .Camrecorded{
    height:30%;
    /* margin-right: 1%; */
    margin-left:0.25cm;
    
  
  }
 
}